/* Dependencies ============================================================= */
import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('CreditApplicationModule', 'green');

/* State ==================================================================== */
const state = () => ({
  initialized: false,
  asset_id: undefined,
  name: undefined,
  identification_number: undefined,
  passport_when: undefined,
  division_code: undefined,
  passport_who: undefined,
  birth_date: undefined,
  birth_place: undefined,
  legal_address: undefined,
  actual_address: undefined,
  phone: undefined,
  email: undefined,
  term: undefined,
  initial_fee: undefined,
  initial_fee_formatted: undefined,
  current_price: undefined,
  current_price_formatted: undefined,
  asset: {},
});

/* Getters ================================================================== */
const getters = {
  application: (state) => {
    return {
      asset_id: state.asset_id,
      name: state.name,
      identification_number: state.identification_number,
      passport_when: state.passport_when,
      division_code: state.division_code,
      passport_who: state.passport_who,
      birth_date: state.birth_date,
      birth_place: state.birth_place,
      legal_address: state.legal_address,
      actual_address: state.actual_address,
      phone: state.phone,
      email: state.email,
      term: state.term,
      initial_fee: state.initial_fee,
      initial_fee_formatted: state.initial_fee_formatted,
      current_price: state.current_price,
      current_price_formatted: state.current_price_formatted,
      asset: state.asset,
    };
  },

  applicationInitialized: (state) => {
    logger.log('getter: applicationInitialized', state.initialized);
    return state.initialized;
  },
};

/* Mutations ================================================================ */

const mutations = {
  SET_INITIALIZED(state, flag) {
    state.initialized = flag;
  },

  SET_APPLICATION(state, application) {
    state.asset_id = application.asset_id;
    state.name = application.name;
    state.identification_number = application.identification_number;
    state.passport_when = application.passport_when;
    state.division_code = application.division_code;
    state.passport_who = application.passport_who;
    state.birth_date = application.birth_date;
    state.birth_place = application.birth_place;
    state.legal_address = application.legal_address;
    state.actual_address = application.actual_address;
    state.phone = application.phone;
    state.email = application.email;
    state.term = application.term;
    state.initial_fee = application.initial_fee;
    state.initial_fee_formatted = application.initial_fee_formatted;
    state.current_price = application.current_price;
    state.current_price_formatted = application.current_price_formatted;
    state.asset = application.asset;
  },
};

/* Actions ================================================================== */

const actions = {
  initApplication({ commit }, application) {
    logger.log('Проставляем начальные данные заявки', application);
    commit('SET_APPLICATION', application);
    commit('SET_INITIALIZED', true);
  },

  updateApplication({ commit, getters }, newData) {
    logger.log('Обновляем данные заявки', newData);
    const application = Object.assign({}, getters.application, newData);
    commit('SET_APPLICATION', application);
  },
};

/* Exports ================================================================== */
export default {
  state,
  getters,
  actions,
  mutations,
};
