<template lang="pug">
.wizard-step-form
  .t-h2-mobile.c-gray100.mt-6.mb-5 Информация об объекте
  .lot-info
    .lot-info__asset(v-if="asset" style="width: 320px")
      asset-card.outlined(:asset="asset")
    .lot-info__asset(v-else style="width: 320px")
      | Нет актива
</template>

<script>
  import { UiBtn } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard.vue';
  import { formErrors } from '@/uikit/mixins/formErrors.js';
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step2', 'green');

  export default {
    components: {
      UiBtn,
      AssetCard,
    },
    mixins: [formErrors],
    props: {
      initData: { type: Object, default: () => {} },
    },

    data() {
      return {
        asset: undefined,
      };
    },

    computed: {
      formData: () => {},
    },

    watch: {
      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },

      formData: {
        handler(newValue) {
          this.$emit('update', newValue);
        },
        deep: true,
      },
    },

    methods: {
      ingresData(data) {
        this.asset = data?.asset;
      },
    },
  };
</script>
