<template lang="pug">
app-layout
  v-app.layout-full.page-wizard
    wizard(
      #default="wizard"
      :controller="wizardController"
      :step="currentStep"
      @finish="onFinish"
      @transition="moveToStep"
    )
      template(v-if="!finished")
        wizard-layout(
          key="process"
          :busy="!applicationInitialized || wizard.busy"
          :busy-msg="!applicationInitialized ? 'Загрузка визарда' : wizard.busyMsg"
          :error="wizard.error"
          :disabled="wizard.busy"
        )
          template(#busy="{ msg }")
            .d-flex.align-center.justify-center(style="min-height: 320px")
              ui-loader(preloder :text="msg")

          // хэдер
          template(#header)
            .d-flex.align-center.justify-space-between.mb-5
              ui-btn.ml-n4(text :href="$path.asset(assetId)")
                ui-icon.mr-1(name="arrow-left")
                | Назад к активу
            .t-h1-mobile.c-gray100 Заявка на получение кредита

          // навигатор по шагам
          template(#navigator)
            div
              .t-caption-small.c-gray80.mb-3(v-if="wizard.currentStepNumber") Шаг {{ wizard.currentStepNumber }} из {{ wizard.totalStepsNumber }}

          // ошибки
          template(#error="{ errorMsg }")
            ui-alert.sh-small(border-bottom-large error rounded) {{ errorMsg }}

          // прогресс заполнения
          template(#progress)
            wizard-progress-linear(:value="wizard.percentage")

          // содержание текущего шага
          template(#default)
            wizard-step-sequencer(
              active-only
              :current-step="wizard.currentStep"
              :steps="wizard.steps"
            )
              // Шаг 1
              template(#step1="{ step, active }")
                wizard-step(
                  :key="step.name"
                  :actions="{ next: updateApplication }"
                  :active="active"
                  :data="application"
                  :guards="{ next: validateStep1 }"
                  :step="step"
                  :transits="['next']"
                )
                  template(#default="{ initialData, ingresData }")
                    step1-form(
                      ref="step1Form"
                      :errors="savingErrors"
                      :init-data="initialData"
                      @update="ingresData"
                    )
                  template(#actions="{ trigger }")
                    ui-btn.mb-2(
                      large
                      primary
                      width="100%"
                      @click="trigger('next')"
                    ) Продолжить
              // Шаг 2
              template(#step2="{ step, active }")
                wizard-step(
                  :key="step.name"
                  :actions="{ next: updateApplication }"
                  :active="active"
                  :data="application"
                  :step="step"
                  :transits="['prev', 'next']"
                )
                  template(#default="{ initialData }")
                    step2-form(:init-data="initialData")
                  template(#actions="{ trigger }")
                    ui-btn.mb-2(
                      large
                      primary
                      width="100%"
                      @click="trigger('next')"
                    ) Продолжить
                    ui-btn.c-primary(large text @click="trigger('prev')")
                      ui-icon(name="chevron-left" left)
                      | Предыдущий шаг

              // Шаг 3
              template(#step3="{ step, active }")
                wizard-step(
                  :key="step.name"
                  :actions="{ finish: submitApplication }"
                  :active="active"
                  :data="application"
                  :guards="{ finish: validateStep3 }"
                  :step="step"
                  :transits="['prev', 'finish']"
                )
                  template(#default="{ initialData, ingresData }")
                    step3-form(
                      ref="step3Form"
                      :errors="savingErrors"
                      :init-data="initialData"
                      @update="ingresData"
                    )
                  template(#actions="{ trigger }")
                    div
                      ui-btn.mb-2(
                        large
                        primary
                        width="100%"
                        @click="trigger('finish')"
                      ) Отправить заявку
                      ui-btn.c-primary(large text @click="trigger('prev')")
                        ui-icon(name="chevron-left" left)
                        | Предыдущий шаг
                        // содержание текущего шага

      // финалочка
      template(v-else)
        wizard-layout(key="finished")
          // хэдер
          template(#header)
            .d-flex.align-center.justify-space-between.mb-5
              ui-btn.ml-n4(text :href="$path.asset(assetId)")
                ui-icon.mr-1(name="arrow-left")
                | Назад к активу
            .t-h1-mobile.c-gray100 Заявка на получение кредита
          template(#default)
            .card-sheet(style="padding: 32px 32px 80px 32px")
              ui-icon.c-primary(name="check-round-full" size=48)
              section.ma-auto(style="width: 100%")
                h2.text--h2.mb-6 Заявка №{{ applicationId }} взята в обработку

                p Благодарим Вас за проявленный интерес к активам и продуктам банка.
                p Специалист банка свяжется с Вами в ближайшее время по указанным контактам для обсуждения конкретных вопросов по предоставлению финансирования на покупку актива.
                .d-flex.justify-end.mt-12
                  ui-btn(
                    large
                    secondary
                    :href="$path.catalog()"
                    width="100%"
                  ) Вернуться в каталог
</template>

<script>
  import { UiIcon, UiBtn, UiAlert, UiLoader } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import Step1Form from '@/views/service_applications/credit/Step1Form.vue';
  import Step2Form from '@/views/service_applications/credit/Step2Form.vue';
  import Step3Form from '@/views/service_applications/credit/Step3Form.vue';
  import {
    okResult,
    failedResult,
    createWizard,
    default as Wizard,
  } from '@/components/application-wizard/Wizard.vue';
  import WizardLayout from '@/components/application-wizard/WizardLayout.vue';
  import WizardStep from '@/components/application-wizard/WizardStep.vue';
  import WizardStepSequencer from '@/components/application-wizard/WizardStepSequencer.vue';
  import WizardNavigator from '@/components/application-wizard/WizardNavigator.vue';
  import WizardProgressLinear from '@/components/application-wizard/WizardProgressLinear.vue';

  import creditApplicationModule from '@/store/credit_application';
  import { service as ServicesAPI } from '@/services/services';
  import { handleError } from '@/libs/handle-error';
  import registerStoreModule from '@/uikit/mixins/registerStoreModule';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageServiceApplicationCredit', 'blue');

  export default {
    components: {
      Step1Form,
      Step2Form,
      Step3Form,
      UiIcon,
      UiBtn,
      UiAlert,
      UiLoader,
      AppLayout,
      Wizard,
      WizardLayout,
      WizardStepSequencer,
      WizardStep,
      WizardNavigator,
      WizardProgressLinear,
    },
    mixins: [registerStoreModule],

    props: {
      assetId: { type: String, default: '' },
      stepName: { type: String, default: '' },
    },

    data() {
      return {
        finished: false,
        applicationId: undefined,
        initialApplication: undefined,
        errorMsg: undefined,
        errorTimer: undefined,
        currentStep: undefined,
        wizardController: undefined,
        savingErrors: {},
      };
    },

    computed: {
      applicationInitialized() {
        return this.$store.state?.credit_application?.initialized;
      },

      application() {
        return this.$store.getters['credit_application/application'];
      },

      wizardStepFromUrl() {
        logger.log('Заявка Кредит. Текущий шаг:', this.stepName);
        if (this.stepName) {
          const wizardStep = this.wizardController.getStep(this.stepName);
          return wizardStep;
        } else {
          return undefined;
        }
      },
    },

    created() {
      this.wizardController = createWizard([
        {
          name: 'step1',
          title: 'Сведения о заемщике',
          progress: 33,
          url: `/service_applications/credit/${this.assetId}/new/step1`,
        },
        {
          name: 'step2',
          title: 'Информация об объекте',
          url: `/service_applications/credit/${this.assetId}/new/step2`,
          progress: 66,
        },
        {
          name: 'step3',
          title: 'Сведения о кредите',
          progress: 100,
          url: `/service_applications/credit/${this.assetId}/new/step3`,
        },
      ]);
    },

    mounted() {
      this.registerStoreModule('credit_application', {
        namespaced: true,
        ...creditApplicationModule,
      });
      this.initialize();
    },

    methods: {
      async initialize() {
        logger.log('Инициализация', this.wizardStepFromUrl?.name, this.application);
        if (!this.applicationInitialized) {
          try {
            logger.log('Инициализация данных', this.application);
            const { application, applicationId } = await ServicesAPI.newCredit(this.assetId);

            if (applicationId) {
              this.applicationId = applicationId;
              this.onFinish();
              return;
            }

            await this.$store.dispatch('credit_application/initApplication', application);
            this.initialApplication = application;
          } catch (error) {
            logger.error('Ошибка инициализации', error);
            this.initialApplication = undefined;
            handleError(error);
          }
        } else {
          logger.log('Инициализация данных из хранилища', this.application);
          this.initialApplication = this.application;
        }
        this.currentStep = this.wizardStepFromUrl || this.wizardController.steps[0];
        logger.log('Инициализация начального шага из урла', this.currentStep);
      },

      updateApplication(data) {
        return new Promise((res, rej) => {
          logger.log('Пополняем форму заявки данными');
          this.$store
            .dispatch('credit_application/updateApplication', data)
            .then(() => res(okResult('Форма обновлена')))
            .catch((err) => rej(failedResult('Не получилось обновить данные формы')));
        });
      },

      async submitApplication(data) {
        let actionResult = okResult();
        try {
          this.savingErrors = {};
          await this.updateApplication(data);
          const { applicationId } = await ServicesAPI.createCreditApplication(this.application);
          logger.log('submitApplication', applicationId);
          this.applicationId = applicationId;
          return okResult('Заявка успешно создана');
        } catch (error) {
          logger.error(error);

          // собираем ошибки для формата, подходящего vee-validate
          const errors = {};
          Object.keys(error.data?.errors).forEach((fieldName) => {
            errors[fieldName] = [error.data.errors[fieldName]];
          });
          logger.log('Собранные ошибки!', errors);
          this.savingErrors = errors;

          actionResult = failedResult('Не удалось создать заявку');
        }
        return actionResult;
      },

      async validateStep1() {
        let res = await this.$refs.step1Form.validate();
        if (!res) return failedResult('Исправьте ошибки на шаге 1');
        return okResult();
      },

      async validateStep3() {
        let res = await this.$refs.step3Form.validate();
        if (!res) return failedResult('Исправьте ошибки на шаге 3');

        return okResult();
      },

      onNavigate(stepName) {
        const step = this.wizardController.getStep(stepName);
        this.moveToStep(step);
      },

      moveToStep(step) {
        if (step === this.currentStep) return;
        logger.log('Переход на шаг', step?.name);

        if (step) {
          logger.log('goToStep', step);
          this.currentStep = step;
          this.$router.push(step.url);
        } else {
          logger.error('Невозможно найти шаг', step);
        }
      },

      onFinish() {
        this.finished = true;
      },
    },
  };
</script>

<style lang="scss"></style>
