<template lang="pug">
.wizard-step-form
  .t-h2-mobile.c-gray100.mt-6.mb-5 Информация о кредите
  validation-observer(ref="form")
    v-form
      v-row
        v-col
          .d-flex.align-center.mb-1
            .t-normal-bold.c-gray100.mr-1 Стоимость объекта
            ui-icon.c-gray60(
              name="alert"
              v-tooltip.top="{ content: 'При изменении стоимости актива на торгах сумма кредита не меняется. Максимальная сумма кредита - 100 млн ' }"
            )
          .t-small.c-text--dimmed.mb-1 Текущая цена на торгах
          .text--price
            | {{ currentPriceFormatted }}
      v-row
        v-col
          .d-flex.align-center.mb-1
            .t-normal-bold.c-gray100 Первоначальный взнос
            ui-icon.c-gray60(
              name="alert"
              right
              v-tooltip.top="{ content: 'Невозможно использовать материнский капитал для первоначального взноса ' }"
            )
          .t-small.c-text--dimmed.mb-1 Не менее 10% ({{ initialFeeFormatted }})
          //- .flex-1.text--price {{ initialFee | currency }}
          //- validation-provider(v-slot="{ errors }" vid="initial_fee_edit")

          validation-provider(
            v-slot="{ errors }"
            vid="initial_fee"
            :rules="{ minmax_fee: { min: initialFeeMin, max: initialFeeMax } }"
          )
            ui-input-slider.text--price(
              v-model="initialFee"
              :max="initialFeeMax"
              :min="initialFeeMin"
            )
              .flex-1.text--price {{ initialFee | currency }}
            v-messages.mt-2.error--text(v-if="errors[0]" :value="errors")
      v-row
        v-col
          .t-normal-bold.c-gray100.mb-2 Срок кредита
          .t-small.c-text--dimmed.mb-1 от 1 до 30 лет
          validation-provider(rules="required" v-slot="{ errors }" vid="term")
            ui-input-slider.text--price.mt-0(v-model="term" :max="30" :min="1")
              .flex-1.text--price {{ term }} {{ term | pluralize("ru", ["год", "года", "лет"]) }}
            v-messages.mt-2.error--text(v-if="errors[0]" :value="errors")
</template>

<script>
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { UiBtn, UiIcon, UiInputSlider } from '@/uikit';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step3', 'green');

  extend('required', {
    ...required,
    message: 'Обязательно для заполнения',
  });

  extend('minmax_fee', {
    validate(value, args) {
      return !value || (value >= args.min && value <= args.max);
    },
    params: ['min', 'max'],
    message: 'Не может быть меньше 10% или больше текущей стоимости',
  });

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      UiBtn,
      UiIcon,
      UiInputSlider,
    },

    inheritAttrs: false,
    props: {
      initData: { type: Object, default: () => {} },
      errors: { type: Object, default: () => {} },
    },

    data() {
      return {
        term: undefined,
        initialFee: undefined,
        currentPrice: undefined,
        // прочее
        initialFeeMin: undefined,
        initialFeeMax: undefined,
        initialFeeFormatted: undefined,
        currentPriceFormatted: undefined,
      };
    },

    computed: {
      formData() {
        return {
          initial_fee: this.initialFee,
          current_price: this.currentPrice,
          term: this.term || 1,
        };
      },
    },

    watch: {
      errors: {
        handler(newValue) {
          if (this.$refs.form) {
            logger.log('333: проставляем ошибки', newValue);
            this.$refs.form.setErrors(newValue);
          }
        },
        immediate: true,
      },

      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },

      formData: {
        handler(newValue) {
          this.$emit('update', newValue);
        },
        deep: true,
      },
    },

    methods: {
      ingresData(data) {
        this.term = data?.term || 1;
        this.initialFee = data?.initial_fee;

        this.currentPrice = data?.current_price;
        this.currentPriceFormatted = data?.current_price_formatted;
        this.initialFeeMin = data?.current_price * 0.1;
        this.initialFeeMax = data?.current_price * 1;
        this.initialFeeFormatted = data?.initial_fee_formatted;
      },

      async validate() {
        return await this.$refs.form.validate();
      },
    },
  };
</script>

<style lang="scss">
  .text--price {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
</style>
