var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-step-form"},[_c('ui-alert',{attrs:{"dense":"","icon-only":"","warning":""}},[_c('div',{staticClass:"t-small"},[_vm._v("Все поля в заявке являются обязательными для заполнения")])]),_c('div',{staticClass:"t-h2-mobile c-gray100 mt-6 mb-5"},[_vm._v("Сведения о заемщике")]),_c('h3',{staticClass:"t-h4-mobile c-gray100 mb-4"},[_vm._v("Паспорт")]),_c('validation-observer',{ref:"form"},[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("ФИО")]),_c('validation-provider',{attrs:{"rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"name",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Серия и номер паспорта")]),_c('validation-provider',{attrs:{"rules":"required|min:12","vid":"identification_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.passport.maska),expression:"maska.passport.maska"}],key:"identification_number",staticClass:"mt-0 pt-0",attrs:{"placeholder":_vm.maska.passport.placeholder,"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.identificationNumber),callback:function ($$v) {_vm.$set(_vm.form, "identificationNumber", $$v)},expression:"form.identificationNumber"}})]}}])})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Дата выдачи")]),_c('validation-provider',{attrs:{"rules":"required","vid":"passport_when"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-picker',{key:"passport_when",attrs:{"hide-details":"auto","allow-empty":false,"error-messages":errors[0]},model:{value:(_vm.form.passportWhen),callback:function ($$v) {_vm.$set(_vm.form, "passportWhen", $$v)},expression:"form.passportWhen"}})]}}])})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Код подразделения")]),_c('validation-provider',{attrs:{"rules":"required|min:7","vid":"division_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.passport_division_code.maska),expression:"maska.passport_division_code.maska"}],key:"division_code",staticClass:"mt-0 pt-0",attrs:{"placeholder":_vm.maska.passport_division_code.placeholder,"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.divisionCode),callback:function ($$v) {_vm.$set(_vm.form, "divisionCode", $$v)},expression:"form.divisionCode"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Кем выдан")]),_c('validation-provider',{attrs:{"rules":"required","vid":"passport_who"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"passport_who",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"","error-messages":errors[0]},model:{value:(_vm.form.passportWho),callback:function ($$v) {_vm.$set(_vm.form, "passportWho", $$v)},expression:"form.passportWho"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Дата рождения")]),_c('validation-provider',{attrs:{"rules":"required","vid":"birth_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-picker',{key:"birth_date",staticClass:"mt-4",attrs:{"hide-details":"auto","allow-empty":false,"error-messages":errors[0]},model:{value:(_vm.form.birthDate),callback:function ($$v) {_vm.$set(_vm.form, "birthDate", $$v)},expression:"form.birthDate"}})]}}])})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Место рождения")]),_c('validation-provider',{attrs:{"rules":"required","vid":"birth_place"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"birth_place",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.birthPlace),callback:function ($$v) {_vm.$set(_vm.form, "birthPlace", $$v)},expression:"form.birthPlace"}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Адрес регистрации")]),_c('validation-provider',{attrs:{"rules":"required","vid":"legal_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"legal_address",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.legalAddress),callback:function ($$v) {_vm.$set(_vm.form, "legalAddress", $$v)},expression:"form.legalAddress"}})]}}])}),_c('div',{staticClass:"d-flex align-start"},[_c('v-checkbox',{attrs:{"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"t-normal c-text"},[_vm._v("Фактический адрес соответствует адресу регистрации")])]},proxy:true}]),model:{value:(_vm.userSameActualAddress),callback:function ($$v) {_vm.userSameActualAddress=$$v},expression:"userSameActualAddress"}})],1)],1)],1),(!_vm.userSameActualAddress)?_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Фактический адрес")]),_c('validation-provider',{attrs:{"rules":"required","vid":"actual_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"actual_address",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.actualAddress),callback:function ($$v) {_vm.$set(_vm.form, "actualAddress", $$v)},expression:"form.actualAddress"}})]}}],null,false,3733834877)})],1)],1):_vm._e(),_c('section',{staticClass:"pt-16"},[_c('h3',{staticClass:"t-h4-mobile c-gray100 mb-4"},[_vm._v("Контакты")]),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Мобильный телефон")]),_c('validation-provider',{attrs:{"rules":"required|phone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.phone.maska),expression:"maska.phone.maska"}],key:"phone",staticClass:"mt-0 pt-0",attrs:{"placeholder":_vm.maska.phone.placeholder,"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Электронная почта")]),_c('validation-provider',{attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"email",staticClass:"mt-0 pt-0",attrs:{"autocomplete":"off","hide-details":"auto","error-messages":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }