<template lang="pug">
.wizard-step-form
  ui-alert(dense icon-only warning)
    .t-small Все поля в&nbsp;заявке являются обязательными для&nbsp;заполнения
  .t-h2-mobile.c-gray100.mt-6.mb-5 Сведения о заемщике

  h3.t-h4-mobile.c-gray100.mb-4 Паспорт
  validation-observer(ref="form")
    v-form
      v-row
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 ФИО
          validation-provider(rules="required" v-slot="{ errors }" vid="name")
            v-text-field.mt-0.pt-0(
              v-model="form.name"
              autocomplete="off"
              hide-details="auto"
              key="name"
              :error-messages="errors[0]"
            )
      v-row
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Серия и номер паспорта
          validation-provider(
            rules="required|min:12"
            v-slot="{ errors }"
            vid="identification_number"
          )
            v-text-field.mt-0.pt-0(
              v-model="form.identificationNumber"
              :placeholder="maska.passport.placeholder"
              autocomplete="off"
              hide-details="auto"
              key="identification_number"
              v-maska="maska.passport.maska"
              :error-messages="errors[0]"
            )
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Дата выдачи
          validation-provider(rules="required" v-slot="{ errors }" vid="passport_when")
            ui-date-picker(
              v-model="form.passportWhen"
              hide-details="auto"
              key="passport_when"
              :allow-empty="false"
              :error-messages="errors[0]"
            )
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Код подразделения
          validation-provider(rules="required|min:7" v-slot="{ errors }" vid="division_code")
            v-text-field.mt-0.pt-0(
              v-model="form.divisionCode"
              :placeholder="maska.passport_division_code.placeholder"
              autocomplete="off"
              hide-details="auto"
              key="division_code"
              v-maska="maska.passport_division_code.maska"
              :error-messages="errors[0]"
            )
      v-row
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Кем выдан
          validation-provider(rules="required" v-slot="{ errors }" vid="passport_who")
            v-text-field.mt-0.pt-0(
              v-model="form.passportWho"
              autocomplete="off"
              hide-details=""
              key="passport_who"
              :error-messages="errors[0]"
            )
      v-row
        v-col.py-2(cols=12 sm=4)
          .t-xsmall.c-text--dimmed.mb-1 Дата рождения
          validation-provider(rules="required" v-slot="{ errors }" vid="birth_date")
            ui-date-picker.mt-4(
              v-model="form.birthDate"
              hide-details="auto"
              key="birth_date"
              :allow-empty="false"
              :error-messages="errors[0]"
            )
        v-col.py-2(cols=12 sm=8)
          .t-xsmall.c-text--dimmed.mb-1 Место рождения
          validation-provider(rules="required" v-slot="{ errors }" vid="birth_place")
            v-text-field.mt-0.pt-0(
              v-model="form.birthPlace"
              autocomplete="off"
              hide-details="auto"
              key="birth_place"
              :error-messages="errors[0]"
            )

      v-row.mt-6
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Адрес регистрации
          validation-provider(rules="required" v-slot="{ errors }" vid="legal_address")
            v-text-field.mt-0.pt-0(
              v-model="form.legalAddress"
              autocomplete="off"
              hide-details="auto"
              key="legal_address"
              persistent-placeholder
              :error-messages="errors[0]"
            )
          .d-flex.align-start
            v-checkbox(v-model="userSameActualAddress" hide-details="auto")
              template(#label)
                .t-normal.c-text Фактический адрес соответствует адресу регистрации

      v-row(v-if="!userSameActualAddress")
        v-col.py-2(cols=12)
          .t-xsmall.c-text--dimmed.mb-1 Фактический адрес
          validation-provider(rules="required" v-slot="{ errors }" vid="actual_address")
            v-text-field.mt-0.pt-0(
              v-model="form.actualAddress"
              autocomplete="off"
              hide-details="auto"
              key="actual_address"
              :error-messages="errors[0]"
            )

      section.pt-16
        h3.t-h4-mobile.c-gray100.mb-4 Контакты
        v-row
          v-col.py-2(cols=12 sm=6)
            .t-xsmall.c-text--dimmed.mb-1 Мобильный телефон
            validation-provider(rules="required|phone" v-slot="{ errors }" vid="phone")
              v-text-field.mt-0.pt-0(
                v-model="form.phone"
                :placeholder="maska.phone.placeholder"
                autocomplete="off"
                hide-details="auto"
                key="phone"
                v-maska="maska.phone.maska"
                :error-messages="errors[0]"
              )
        v-row
          v-col.py-2(cols=12 sm=6)
            .t-xsmall.c-text--dimmed.mb-1 Электронная почта
            validation-provider(rules="required|email" v-slot="{ errors }" vid="email")
              v-text-field.mt-0.pt-0(
                v-model="form.email"
                autocomplete="off"
                hide-details="auto"
                key="email"
                :error-messages="errors[0]"
              )
</template>

<script>
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required, min, email } from 'vee-validate/dist/rules';
  import { UiBtn, UiAlert, UiDatePicker } from '@/uikit';
  import { maska } from 'maska';
  import Constants from '@/uikit/util/constants';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step1Form', 'green');

  extend('required', { ...required, message: 'Обязательно для заполнения' });
  extend('min', { ...min, message: 'Минимальная длина: {length} символов' });
  extend('email', { ...email, message: 'Укажите корректный емейл' });
  extend('phone', {
    validate: (value) => value.match(Constants.PHONE_REGEX),
    message: 'Укажите корректный телефон',
  });

  export default {
    components: {
      UiBtn,
      UiAlert,
      UiDatePicker,
      ValidationObserver,
      ValidationProvider,
    },
    directives: { maska },
    props: {
      errors: { type: Object, default: () => {} },
      initData: { type: Object, default: () => {} },
    },

    data() {
      return {
        maska: Constants.fields,
        userSameActualAddress: true,
        form: {
          name: undefined,
          identificationNumber: undefined,
          passportWhen: undefined,
          passportWho: undefined,
          divisionCode: undefined,
          birthDate: undefined,
          birthPlace: undefined,
          legalAddress: undefined,
          actualAddress: undefined,
          phone: undefined,
          email: undefined,
        },
      };
    },

    computed: {
      formData() {
        const actualAddress = this.userSameActualAddress
          ? this.form.legalAddress
          : this.form.actualAddress;
        return {
          name: this.form.name,
          identification_number: this.form.identificationNumber,
          passport_when: this.form.passportWhen,
          passport_who: this.form.passportWho,
          division_code: this.form.divisionCode,
          birth_date: this.form.birthDate,
          birth_place: this.form.birthPlace,
          legal_address: this.form.legalAddress,
          actual_address: actualAddress,
          phone: this.form.phone,
          email: this.form.email,
        };
      },
    },

    watch: {
      errors: {
        handler(newValue) {
          if (this.$refs?.form) {
            logger.log('111: проставляем ошибки', newValue);
            this.$refs.form.setErrors(newValue);
          }
        },
        immediate: true,
      },

      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },

      formData: {
        handler(newValue) {
          this.$emit('update', newValue);
        },
        deep: true,
      },
    },

    mounted() {},

    methods: {
      ingresData(data) {
        this.form.name = data?.name;
        this.form.identificationNumber = data?.identification_number;
        this.form.passportWhen = data?.passport_when;
        this.form.passportWho = data?.passport_who;
        this.form.divisionCode = data?.division_code;
        this.form.birthDate = data?.birth_date;
        this.form.birthPlace = data?.birth_place;
        this.form.legalAddress = data?.legal_address;
        this.form.actualAddress = data?.actual_address;
        this.form.phone = data?.phone;
        this.form.email = data?.email;

        this.userSameActualAddress =
          !this.form.actualAddress || this.form.actualAddress === this.form.legalAddress;
      },

      async validate() {
        return await this.$refs.form.validate();
      },
    },
  };
</script>
